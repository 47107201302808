<template>
  <div>
    
  </div>
</template>

<script>
export default {
  name: 'Tempalte',
  props: {},
  data: () => {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";

</style>
