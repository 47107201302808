var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sg-admin-box"},[_c('div',{class:{ 'sg-admin-left-menus': true, 'sg-collapse': _vm.isCollapse }},[_c('div',{staticClass:"sg-admin-title sg-space-around"},[_c('el-dropdown',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapse),expression:"!isCollapse"}],attrs:{"placement":"bottom"},on:{"visible-change":_vm.updateUserDropdown}},[_c('span',{staticClass:"sg-flex-center sg-white"},[_c('img',{staticClass:"sg-avatar sg-margin-right-sm",attrs:{"src":"https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/logo-4.png"}}),_c('div',{staticClass:"sg-name sg-margin-right-sm"},[_vm._v("Admin")]),_c('i',{class:{
                'el-icon-arrow-up sg-menu-trans': true,
                'sg-rotate': _vm.userDropdown,
              }})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('div',{on:{"click":function($event){return _vm.addTab({ name: '个人信息', url: 'AdminProfile' })}}},[_vm._v(" 个人信息 ")])]),_c('el-dropdown-item',[_c('div',{on:{"click":_vm.logout}},[_vm._v("退出系统")])])],1)],1),_c('i',{class:{
            'sg-menu-icon sg-menu-toggle-btn': true,
            'el-icon-s-unfold': _vm.isCollapse,
            'el-icon-s-fold': !_vm.isCollapse,
          },on:{"click":_vm.switchCollapse}})],1),_c('el-menu',{staticClass:"sg-left-nav-menu",attrs:{"background-color":"#000000","text-color":"#fff","active-text-color":"#409EFF","collapse":_vm.isCollapse,"collapse-transition":true},on:{"select":_vm.selectMenu}},[_vm._l((_vm.menus),function(menu,index){return [(menu.children && menu.children.length > 0 && menu.show == 1)?_c('el-submenu',{key:index,attrs:{"index":'' + index}},[_c('template',{slot:"title"},[_c('i',{class:menu.icon}),_c('span',[_vm._v(_vm._s(menu.name))])]),_vm._l((menu.children),function(sub_menu,i_index){return [(sub_menu.show == 1)?_c('el-menu-item',{key:index + '-' + i_index,attrs:{"index":sub_menu.url,"route":sub_menu}},[_c('template',{slot:"title"},[_c('i',{class:sub_menu.icon}),_c('span',[_vm._v(_vm._s(sub_menu.name))])])],2):_vm._e()]})],2):(menu.show == 1)?_c('el-menu-item',{key:index,attrs:{"index":menu.url,"route":menu}},[_c('i',{class:menu.icon}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(menu.name))])]):_vm._e()]})],2)],1),_c('div',{class:{ 'sg-admin-right': true, 'sg-collapse': _vm.isCollapse }},[_c('div',{class:{
          'sg-admin-top sg-fix-top': true,
          'sg-collapse': _vm.isCollapse,
        }},[_c('div',{staticClass:"sg-nav-top sg-space-between sg-border-bottom"},[_c('div',{staticClass:"sg-tab-header-box"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:item.url,class:{
                'sg-tab-header sg-pad sg-font sg-border-right sg-flex-center sg-nowrap': true,
                'sg-selected sg-main-color': item.url == _vm.activeUrl,
              },on:{"click":function($event){return _vm.selectTab(item.url)}}},[_vm._v(" "+_vm._s(item.name)+" "),(item.url != _vm.homeTabUrl)?_c('i',{class:{
                  'el-icon-close': true,
                },on:{"click":function($event){$event.stopPropagation();return _vm.closeTab(index)}}}):_vm._e()])}),0),_c('el-button',{staticClass:"sg-margin-left sg-margin-right",attrs:{"icon":"el-icon-delete","type":"danger"},on:{"click":_vm.closeAll}})],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"sg-admin-body",attrs:{"element-loading-text":"拼命加载中"}},[_c('div',{staticClass:"sg-tab-box"},[_vm._l((_vm.tabs),function(item,index){return [(item.url)?_c(item.url,{directives:[{name:"show",rawName:"v-show",value:(item.url == _vm.activeUrl),expression:"item.url == activeUrl"}],key:index,tag:"component"}):_vm._e()]})],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }