<template>
  <div>
    <div class="sg-admin-box">
      <div :class="{ 'sg-admin-left-menus': true, 'sg-collapse': isCollapse }">
        <div class="sg-admin-title sg-space-around">
          <el-dropdown
            placement="bottom"
            @visible-change="updateUserDropdown"
            v-show="!isCollapse"
          >
            <span class="sg-flex-center sg-white">
              <img
                src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/logo-4.png"
                class="sg-avatar sg-margin-right-sm"
              />
              <div class="sg-name sg-margin-right-sm">Admin</div>
              <i
                :class="{
                  'el-icon-arrow-up sg-menu-trans': true,
                  'sg-rotate': userDropdown,
                }"
              ></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="addTab({ name: '个人信息', url: 'AdminProfile' })">
                  个人信息
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div @click="logout">退出系统</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <i
            :class="{
              'sg-menu-icon sg-menu-toggle-btn': true,
              'el-icon-s-unfold': isCollapse,
              'el-icon-s-fold': !isCollapse,
            }"
            @click="switchCollapse"
          ></i>
        </div>
        <el-menu
          background-color="#000000"
          text-color="#fff"
          active-text-color="#409EFF"
          :collapse="isCollapse"
          :collapse-transition="true"
          class="sg-left-nav-menu"
          @select="selectMenu"
        >
          <template v-for="(menu, index) in menus">
            <el-submenu
              v-if="menu.children && menu.children.length > 0 && menu.show == 1"
              :key="index"
              :index="'' + index"
            >
              <template slot="title">
                <i :class="menu.icon"></i>
                <span>{{ menu.name }}</span>
              </template>
              <template v-for="(sub_menu, i_index) in menu.children">
                <el-menu-item
                  v-if="sub_menu.show == 1"
                  :key="index + '-' + i_index"
                  :index="sub_menu.url"
                  :route="sub_menu"
                >
                  <template slot="title">
                    <i :class="sub_menu.icon"></i>
                    <span>{{ sub_menu.name }}</span>
                  </template>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item
              :key="index"
              :index="menu.url"
              :route="menu"
              v-else-if="menu.show == 1"
            >
              <i :class="menu.icon"></i>
              <span slot="title">{{ menu.name }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
      <div :class="{ 'sg-admin-right': true, 'sg-collapse': isCollapse }">
        <div
          :class="{
            'sg-admin-top sg-fix-top': true,
            'sg-collapse': isCollapse,
          }"
        >
          <div class="sg-nav-top sg-space-between sg-border-bottom">
            <div class="sg-tab-header-box">
              <div
                :class="{
                  'sg-tab-header sg-pad sg-font sg-border-right sg-flex-center sg-nowrap': true,
                  'sg-selected sg-main-color': item.url == activeUrl,
                }"
                v-for="(item, index) in tabs"
                :key="item.url"
                @click="selectTab(item.url)"
              >
                {{ item.name }}
                <i
                  :class="{
                    'el-icon-close': true,
                  }"
                  v-if="item.url != homeTabUrl"
                  @click.stop="closeTab(index)"
                ></i>
                <!-- v-if="item.url !== homeTabUrl" -->
              </div>
            </div>
            <el-button
              icon="el-icon-delete"
              class="sg-margin-left sg-margin-right"
              type="danger"
              @click="closeAll"
            ></el-button>
          </div>
        </div>
        <div
          class="sg-admin-body"
          v-loading="loading"
          element-loading-text="拼命加载中"
        >
          <!-- <router-view /> -->
          <div class="sg-tab-box">
            <template v-for="(item, index) in tabs">
              <component
                :is="item.url"
                v-if="item.url"
                :key="index"
                v-show="item.url == activeUrl"
              ></component>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Template from "../Template.vue";

export default {
  components: { Template },
  name: "Layout",
  props: {
    msg: String,
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.admin.isLogin,
      loading: (state) => state.loading,
      menus: (state) => state.admin.admin_menus,
    }),
    // homeTabUrl: () => "AdminArticle",
    homeTabUrl: () => "AdminHome",
  },
  data: () => {
    return {
      isCollapse: false,
      userDropdown: false,
      tabs: [
        {
          name: "文章管理",
          url: "AdminArticle",
        },
      ],
      activeUrl: "AdminArticle",
    };
  },
  methods: {
    logout() {
      this.$store.commit("admin/logout");
    },
    closeTab(index) {
      this.tabs.splice(index, 1);
      this.activeUrl = this.tabs[index - 1].url;
    },
    closeAll() {
      var homeTabUrl = this.homeTabUrl;
      this.tabs = this.tabs.filter((item) => item.url == homeTabUrl);
      this.activeUrl = homeTabUrl;
    },
    selectTab(url) {
      this.activeUrl = url;
    },
    tabRemove(url) {
      for (let i = 0; i < this.tabs.length; ++i) {
        if (this.tabs[i].url == url) {
          this.tabs.splice(i, 1);
          if (this.activeUrl == url) this.activeUrl = "AdminHome";
          return true;
        }
      }
    },
    addTab(data) {
      var tabs = this.tabs;
      for (let i = 0; i < tabs.length; ++i) {
        if (tabs[i].url == data.url) {
          this.activeUrl = data.url;
          return;
        }
      }
      this.tabs.push(data);
      this.activeUrl = data.url;
    },
    selectMenu(_index, _indexPath, e) {
      // console.log(index, indexPath, e)
      this.addTab(e.route);
    },
    updateUserDropdown(val) {
      this.userDropdown = val;
    },
    switchCollapse: function () {
      this.isCollapse = !this.isCollapse;
    },
    getMenus: function () {
      // let that = this
      this.$store.dispatch("admin/getList", {
        url: "admin-menus/get",
        key: "admin_menus",
      });
    },
  },
  mounted() {
    if (!this.isLogin) {
      this.$router.push({
        path: "/admin/login",
      });
    } else {
      this.getMenus();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-nav-top {
  height: 50px;
}
.sg-tab-header-box {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  // &::-webkit-scrollbar {
  //   display: none; /* Chrome Safari */
  // }
  // &:hover {
  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background: black;
  }
  // }
}
.sg-tab-header {
  cursor: pointer;
  transition: $tab-transition;
  i {
    margin-left: 5px;
    border-radius: 50%;
    &:hover {
      background: gray;
      color: white;
    }
  }
  .sg-hover-show {
    width: 0;
    overflow: hidden;
    transition: $tab-transition;
    border-radius: 50%;
    margin-left: 5px;

    &:hover {
      background: gray;
      color: white;
    }
  }
  &:hover {
    color: $main-color;
    .sg-hover-show {
      width: 14px;
    }
  }
}
.sg-menu-toggle-btn {
  font-size: $font-icon;
}
.sg-left-nav-menu.el-menu {
  overflow-y: auto;
  .el-menu-item {
    background: #091c32;

    &:hover {
      background: $admin-menu-bg !important;
      color: $main-color !important;
    }
  }
  .el-submenu__title:hover {
    background: $admin-menu-bg !important;
    color: $main-color !important;
  }
}
.sg-menu-icon:hover {
  color: $main-color;
}
.sg-admin-body {
  padding: (50px + $pad) $pad $pad $pad;
  min-height: 100vh;
  box-sizing: border-box;
}
.sg-admin-left-menus {
  height: calc(100vh - #{$admin-title-height});
  z-index: 1000;

  .el-menu {
    height: calc(100vh - #{$admin-title-height});
    overflow-y: auto;
    border-width: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.sg-admin-left-menus .el-menu:not(.el-menu--collapse) {
  width: $admin-menu-width;
}
.sg-admin-title {
  background: #002140;
  color: white;
  height: $admin-title-height;
  line-height: $admin-title-height;
  text-align: center;
  width: $admin-menu-width;
  font-weight: bold;
  transition: width 0.3s ease-in-out;
}
.sg-admin-left-menus {
  position: fixed;
  top: 0;
  left: 0;
}
.sg-admin-right {
  background: $admin-body-bg;
  width: 100%;
  padding-left: $admin-menu-width;
  box-sizing: border-box;
  transition: $menu-transition;
}
.sg-admin-left-menus.sg-collapse .sg-admin-title {
  width: $admin-menu-width-collapse;
}
.sg-admin-right.sg-collapse {
  padding-left: $admin-menu-width-collapse;
}
.sg-admin-top {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  background: white;
  height: $admin-title-height;
  box-sizing: border-box;
  transition: $menu-transition;
  left: 250px;

  &.sg-collapse {
    left: $admin-menu-width-collapse;
  }

  .sg-left {
    font-size: $admin-title-height - $pad * 2;
  }

  .sg-right {
    display: flex;
    align-items: center;

    img,
    div {
      margin-right: calc($pad / 2);
    }
  }
}
.sg-avatar {
  width: $avatar-width;
  height: $avatar-width;
  border-radius: 50%;
}
</style>
